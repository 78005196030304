import React from 'react'
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles'
import { Box, Typography, Button, Select, SelectChangeEvent, Menu, MenuItem, FormControl, InputLabel } from '@mui/material'
import AssessRiskMenu from './AssessRiskMenu';
import logo from '../imgs/wwf_logo.png'

export default function MainHeader() {
    const theme = useTheme();

    const onClickEvent = (e: SelectChangeEvent) => {
        e.preventDefault()
        console.log(e.target.value as string);
        if (e.target.value == 'ASSESS RISK') {

        }

    }

    const StyledLink = ({ props }: any) => {

        return (

            <Box flexDirection='row' alignSelf='center'>
                <Button
                    component={Link}
                    style={{ textDecoration: 'none', color: theme.palette.primary.contrastText }}
                    to={props == 'Home' ? '/' : props.toLowerCase().replaceAll(' ', '-')}
                >
                    <Typography variant='body2'>{props.toUpperCase()}</Typography>
                </Button>
            </Box>
        )
    }

    return (
        <Box maxHeight='10vh'>
            <img style={{ zIndex: 3000, left: '15px', position: 'absolute', height: '130px' }} src={logo} alt='wwf' />
            <Box display='flex'
                flexDirection='row'
                justifyContent='center'
                alignItems='center'
                sx={{
                    height: '10vh', width: '100%', background: theme.palette.primary.main
                }}>

                <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-around'
                    alignItems='center'
                    width='60%'
                >
                    <StyledLink props='Home' />
                    <StyledLink props='About' />
                    <AssessRiskMenu />
                    <StyledLink props='Understand Risk' />
                    <StyledLink props='Manage Risk' />
                    <StyledLink props='Contact' />
                </Box>
            </Box >
        </Box>
    )
}