import React from 'react'
import { useTheme } from '@mui/material/styles'
import {Box, Link, Typography} from '@mui/material'

function Footer() {
    const theme = useTheme();

    return (
        <Box
            sx={{
                display: 'flex',
                background: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                marginTop: 'auto',
                height: '9vh',
                padding: '10px',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-evenly'
            }}
        >
            <Link href='/acronyms' variant='body2' underline='hover' color="inherit">Acronyms</Link>
            <Link href='/wagtail/' variant='body2' underline='hover' color="inherit">Admin</Link>
        </Box>
    )
}

export default Footer