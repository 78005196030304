import PageContainer from './PageContainer';
import * as React from 'react';
import {redirect, useLocation, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Box, Button} from "@mui/material";
import logo from "../imgs/main_woodrat_logo.png"
function BoxWrap({children}: any) {

    return (
        <Box sx={{
            height: '200px',
            width: '60%',
            margin: '10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center'
        }}>
            {children}
        </Box>
    )

}

function ContentPage() {
    let {slug} = useParams();
    let navigate = useNavigate();
    const [contentItem, setContentItem] = useState<{ title: string, content?: { __html: string } }>({title: ''});
    useEffect(() => {
        const currentSlug = slug ? slug : 'home';
        fetch(`/wagtail/api/v2/pages/?type=cms.ContentPage&slug=${currentSlug}&fields=*`).then(r => r.json()).then((r: any) => {
            if (r.items.length === 1) {
                setContentItem({title: r.items[0].title, content: {__html: r.items[0].body}});
            } else {
                navigate('/404')
            }
        })
    }, [slug])

    return (
        <PageContainer style={{alignItems: 'center'}} title={''}>
            <img src={logo} alt={"WoodRAT"} style={{width: '400px', position: 'relative', top: '-200px', marginBottom: '-200px'}}/>
            <div style={{width: '80%'}} dangerouslySetInnerHTML={contentItem.content}></div>
            <h1>ASSESS RISK</h1>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                width: '100%',
                flexWrap: 'wrap'
            }}>
                <a href={'/assess-risk/species'} className={'risk-button'}>
                    <img alt={'Species'} src={'/api-images/sL02uwqiSUjR7wkSyLyhAcb6HKE=/6/original/'}/>
                    <h1>SPECIES</h1>
                </a>
                <a href={'/assess-risk/countries'} className={'risk-button'}>
                    <img alt={'Species'} src={'/api-images/hILrENvQbZoZFlcypz0Vb2YUPdY=/4/original/'}/>
                    <h1>ORIGIN</h1>
                </a>
            </div>

            <a href={'/understand-risk'} style={{
                backgroundColor: '#154360',
                width: '100%',
                height: '400px',
                display: 'flex',
                justifyContent: 'space-evenly',
                flexWrap: 'wrap',
                overflow: 'hidden',
                alignItems: 'center',
                textDecoration: 'none'
            }}>
                <div style={{color: 'white', width: '200px'}}>
                    <h1 style={{margin: 0}}>UNDERSTAND RISK</h1>
                    <h3>How forest- and timber-related risks are identified, gauged and communicated</h3>
                </div>
                <div>
                    <img width={'300px'} src={'/api-images/sNjOF8MD0EkcD3fCRmlJ08-9VbY=/9/original/'}
                         alt={'tree canopy'}/>
                </div>
                <div>
                    <img width={'300px'} src={'/api-images/UKO8Y8XEN_enE49NnY03cTl5gxQ=/10/original/'}
                         alt={'tree canopy'}/>
                </div>
            </a>


            <a href={'/manage-risk'} style={{
                backgroundImage: "url('/api-images/X48Wl2LKSp-DCrekCsxqa6VqhS8=/11/original/')",
                textAlign: 'center',
                width: '100%',
                height: '400px',
                color: 'white',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                backgroundSize: 'cover',
                textDecoration: 'none'
            }}>
                <h1 style={{fontSize: 'xxx-large', margin: 0}}>MANAGE RISK</h1>
                <h3>WWF’s guidance on managing risk though responsible sourcing</h3>
            </a>
        </PageContainer>
    );
}

export default ContentPage