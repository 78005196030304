import React from 'react'
import { Box } from '@mui/material'


type Props = {
    children: React.ReactNode,
    style?: React.CSSProperties,
}

function DetailedContainer({ children, style }: Props) {
    return (

        <Box
            id='detailed-container'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            maxWidth='1000px'
            minWidth='400px'
            paddingLeft='15px'
            paddingRight='15px'
            sx={{
                ...style,
            }}
        >
            {children}
        </Box>
    )
}

export default DetailedContainer