import PageContainer from './PageContainer';
import * as React from 'react';
import {redirect, useLocation, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";


function ContentPage() {
    let {slug} = useParams();
    let navigate = useNavigate();
    const [contentItem, setContentItem] = useState<{ title: string, content?: { __html: string } }>({title: ''});
    useEffect(() => {
        const currentSlug = slug ? slug : 'home';
        fetch(`/wagtail/api/v2/pages/?type=cms.ContentPage&slug=${currentSlug}&fields=*`).then(r => r.json()).then((r: any) => {
            if (r.items.length === 1) {
                setContentItem({title: r.items[0].title, content: {__html: r.items[0].body}});
            } else {
                navigate('/404')
            }
        })
    }, [slug])

    return (
        <PageContainer style={{alignItems: 'center'}} title={contentItem.title}>

            <div style={{width: '80%'}} dangerouslySetInnerHTML={contentItem.content}></div>
        </PageContainer>
    );
}

export default ContentPage