import { useEffect, useState, useRef, useCallback } from "react";
import { Link, Outlet, useParams, useNavigate } from "react-router-dom";
import PageContainer from "./PageContainer";
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  TextField,
  LinearProgress,
  CircularProgress,
  Stack,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
} from "@mui/x-data-grid";
import useCountries from "../hooks/useCountries";
import SearchBar from "../components/SearchBar";
import DetailedCountry from "../pages/DetailedCountry";
import NoRowsMessage from "../components/NoRowsMessage";
import { GridNoResultsOverlay } from "@mui/x-data-grid/components/GridNoResultsOverlay";

interface Row {
  id: number;
  name: string;
  ipi_score: string;
  timber_risk_score: string;
  cpi_score: string;
}

export default function AssessRiskCountries() {
  const ref = useRef<HTMLDivElement>(null);
  const currentRef: any = ref.current;
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("name");
  const { allCountries, updateCountries, searchCountries, loadingCountries } =
    useCountries();
  const [pageRefresh, setPageRefresh] = useState<boolean>(false);
  const [rows, setRows] = useState<Row[]>([]);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setRows([]);
    //console.log(allCountries);

    allCountries.items?.map((country: any) => {
      let picked = {
        id: country.id,
        name: country.name,
        ipi_score: country.ipi_score,
        timber_risk_score: country.timber_risk_score,
        cpi_score: country.cpi_score,
        slug: country.meta.slug,
      };
      setRows((rows) => [...rows, picked]);
    });

    //scrolls to the top on next/previous -- could be better
    if (currentRef) {
      currentRef.querySelector(".MuiDataGrid-virtualScroller").scrollTop = 0;
    }
    //console.log(allCountries)
    setPageRefresh(false);
  }, [allCountries, pageRefresh]);

  useEffect(() => {
    const offset = page * 20;
    searchCountries(searchInput, sortOrder, offset);
  }, [searchInput, sortOrder, page]);
  // const inputSearch = () => {
  //     // setPageRefresh(true);
  //     searchCountries(searchInput)
  // }

  // const checkIfBlank = (e: any) => {
  //
  //     setSearchInput(e.target.value)
  //     console.log('blank')
  //     if (e.target.value.length == 0) {
  //         getCountries(search)
  //     }
  // }
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "name",
      headerName: "Country",
      editable: false,
      sortable: true,
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ipi_score",
      headerName: "Index of Public Integrity (IPI) Score",
      editable: false,
      sortable: true,
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "timber_risk_score",
      headerName: "Timber Sourcing Hub Risk Score",
      editable: false,
      sortable: true,
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "cpi_score",
      headerName: "Corruption Perceptions Index (CPI) Score",
      editable: false,
      sortable: true,
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  // const CustomFooter = () => {
  //
  //     const previousClick = () => {
  //         updateCountries(allCountries.previous);
  //         setPageRefresh(true);
  //     }
  //
  //     const nextClick = () => {
  //         //console.log('next')
  //         updateCountries(allCountries.next);
  //         setPageRefresh(true)
  //
  //     }
  //
  //     return (
  //         <Box
  //             display='flex'
  //             flexDirection='row'
  //             justifyContent='flex-end'
  //             alignItems='center'
  //         >
  //             <Typography variant="body1" color="initial">Total: {allCountries.count} |</Typography>
  //             <Button disabled={!allCountries.previous} onClick={() => {
  //                 previousClick()
  //             }}>Previous</Button>
  //             <Button disabled={!allCountries.next} onClick={() => {
  //                 nextClick()
  //             }}>Next</Button>
  //
  //         </Box>
  //     )
  // }

  const keyPress = (e: any) => {
    // if (e.keyCode == 13) {
    setSearchInput(e.target.value);
    // inputSearch();
    // }
  };

  const handleCellSelect = (params: GridCellParams) => {
    //console.log(params.id);
    // console.log(params.value);
    navigate(`/assess-risk/countries/${params.row.slug}`, {
      state: {
        id: params.id,
        name: params.value,
        slug: params.row.slug,
      },
    });
  };

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    // searchCountries({sortModel: [...sortModel]});
    console.log(sortModel);
    const sortOrder = sortModel
      .map((f) => `${f.sort === "desc" ? "-" : ""}${f.field}`)
      .join(",");
    console.log(sortOrder);
    setSortOrder(sortOrder);
  }, []);

  return (
    <PageContainer title="Assess Country Risk">
      <Outlet />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        height="620px"
      >
        <Box
          display="flex"
          width="80%"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <TextField
            // onChange={(e) => {
            //     checkIfBlank(e)
            // }}
            onKeyUp={keyPress}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            label="Search for Country"
            sx={{
              flex: 2,
              // width: '75%',
              marginTop: "10px",
              marginBottom: "10px",
              marginRight: "10px",
            }}
          />
          <Button
            onClick={() => {
              // inputSearch()
            }}
            sx={{ padding: "12px", marginRight: "5px", width: "15%" }}
            variant="contained"
          >
            Search
          </Button>
          <Tooltip
            title={"Download this table as an Excel sheet."}
            placement={"top"}
          >
            <Button
              href="/api/export/countries/"
              target="_blank"
              variant="contained"
              sx={{
                padding: "12px",
                marginRight: "5px",
                width: "15%",
              }}
            >
              Download Countries
              <InfoOutlinedIcon
                fontSize={"small"}
                style={{ marginLeft: "3px" }}
              />
            </Button>
          </Tooltip>
        </Box>
        <DataGrid
          ref={ref}
          sx={{
            width: "80%",
            height: "100%",
          }}
          components={{
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: () => (
              <Stack
                height="100%"
                marginLeft="20px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                WWF has not included the country you have searched for because
                we have not identified it as a significant producer or processor
                of wood products. If you believe we are in error, please contact
                us at info@wwfwoodrat.org
              </Stack>
            ),
          }}
          componentsProps={{
            footer: allCountries,
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          loading={loadingCountries}
          rows={rows}
          columns={columns}
          rowsPerPageOptions={[]}
          disableColumnFilter
          disableColumnMenu
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          pagination
          rowCount={
            allCountries.meta !== undefined ? allCountries.meta.total_count : 0
          }
          page={page}
          pageSize={20}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onCellClick={(params: GridCellParams) => {
            handleCellSelect(params);
          }}
        />
      </Box>
    </PageContainer>
  );
}
