import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { faker } from '@faker-js/faker';
import headerImage from '../assets/header.png'
import woodHeader from '../imgs/wood_header_transparent.png';

type Props = {
    title: string
}

function PageHeader({ title }: Props) {
    const theme = useTheme();
    return (
        <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            //height='10vh'

            height='200px'
            width='100%'
            id='page-header'
            sx={{
                background: theme.palette.secondary.main,
                marginBottom: '15px',
                backgroundImage: `url(${woodHeader})`,
                color: 'white',
                backgroundSize: 'cover'
            }}
        >
            <Typography sx={{ zIndex: '1000' }} variant='h3'>{title}</Typography >
            {/* <img style={{ position: 'absolute', height: '200px', width: '500px' }} src={faker.image.nature(500, 200)} /> */}
            {/* <img style={{ height: '200px', width: '500px' }} src={headerImage} /> */}

        </Box>
    )
}

export default PageHeader