import { Container, CssBaseline, Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles'
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import MainHeader from './components/MainHeader';
import Footer from './components/Footer';
import './App.css'
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import AssessRiskCountries from './pages/AssessRiskCountries';
import AssessRiskSpecies from './pages/AssessRiskSpecies';
import ManageRisk from './pages/ManageRisk';
import UnderstandRisk from './pages/UnderstandRisk';
import DetailedCountry from './pages/DetailedCountry';
import DetailedSpecies from './pages/DetailedSpecies';
import CompareCountries from './pages/CompareCountries';
import ContentPage from './pages/ContentPage';
import theme from './theme';
import HomePage from "./pages/HomePage";

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box display='flex' flexDirection='column' minHeight='100vh'>
          <BrowserRouter>

            <MainHeader />
            <Routes>
              <Route path='/' element={<HomePage />} />
              {/*<Route path='/about' element={<About />} />*/}
              <Route path='/assess-risk/countries' element={<AssessRiskCountries />} />
              <Route path='/assess-risk/species' element={<AssessRiskSpecies />} />
              {/*<Route path='/understand-risk' element={<UnderstandRisk />} />*/}
              {/*<Route path='manage-risk' element={<ManageRisk />} />*/}
              {/*<Route path='contact' element={<Contact />} />*/}
              <Route path='/assess-risk/countries/:countryId' element={<DetailedCountry />} />
              <Route path='/assess-risk/species/:speciesId' element={<DetailedSpecies />} />
              <Route path='/compare' element={<CompareCountries />} />
              <Route path='/:slug' element={<ContentPage />} />
              <Route path='*' element={<Navigate replace to='/' />} />
            </Routes>
          </BrowserRouter>
           <Footer />
        </Box>
      </ThemeProvider>
    </>
  );
}

export default App;

