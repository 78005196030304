import { useState, useEffect } from 'react'
import { getSupportedCodeFixes } from 'typescript'

export default function useSpecies(url = '/wagtail/api/v2/pages/?type=main.Species&fields=scientific_name,common_names(*)') {

    const [allSpecies, setSpecies] = useState<any>([])
    const [loadingSpecies, setLoadingSpecies] = useState<any>(true)



    // const getSpecies = async () => {
    //     const results = await fetch(url)
    //         .then((res) => res.json())
    //         .catch((err) => { console.log(err) })
    //     setSpecies(results)
    // }
    const updateSpecies = async (url: string) => {
        setLoadingSpecies(true)
        let searchUrl = url.split('.com');
        console.log(searchUrl);
        const results = await fetch(searchUrl[1])
            .then((res) => res.json())
            .catch((err) => { console.log(err) })
            .finally(() => setLoadingSpecies(false))
        console.log(results);
        setSpecies(results)
    }

    const searchSpecies = async (input: string, sortOrder: string, offset: number) => {
        setLoadingSpecies(true)
        const s = input ? `&search=${input}` : '';
        const sort = sortOrder ? `&order=${sortOrder}` : '';
        const o = offset ? `&offset=${offset}` : '';
        const searchTerm = `${url}${sort}${s}${o}`
        const results = await fetch(searchTerm)
            .then((res) => res.json())
            .catch((err) => { console.log(err) })
            .finally(() => setLoadingSpecies(false))
        console.log(results);
        setSpecies(results);

    }



    // useEffect(() => {
    //     getSpecies()
    //
    //
    // }, [])



    return { allSpecies, updateSpecies, searchSpecies, loadingSpecies }
}