
import { Box } from '@mui/material';

interface BoxWrapperProps {
    children: React.ReactNode;
    style?: React.CSSProperties;
}
export const BoxWrapper = ({ children, style }: BoxWrapperProps) => {

    return (
        <Box
            display='flex'
            flexDirection='column'
            width='100%'
            sx={{
                //padding: '10px',
                boxShadow: 4,
                borderRadius: '10px',
                marginBottom: '10px',
                minHeight: '10px',
                ...style
            }}
        >
            {children}
        </Box>

    )
}