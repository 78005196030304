import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import PageContainer from "./PageContainer";
import DetailedContainer from "./DetailedContainer";
import { useTheme } from "@mui/material/styles";
import { convertFromHtml } from "../constants";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography, Box, Button, Tooltip } from "@mui/material";
import { BoxWrapper } from "../components/BoxWrapper";

import SpeciesInfoTable from "../components/species/SpeciesInfoTable";
import CountriesInSpecies from "../components/species/CountriesInSpecies";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function DetailedSpecies() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  // const { data } = state;
  const [speciesData, setSpeciesData] = useState<any>();
  //console.log(data);

  const fetchInfo = async (countrySlug: any) => {
    //console.log(countryId)
    const results = await fetch(
      `/wagtail/api/v2/pages/?type=main.Species&fields=*,commercial_uses(*),countries(name,live,slug,risk_summary_reports,ipi_score,timber_risk_score,cpi_score),iucn_red_listing(*),cites_listing(name)&slug=${countrySlug}`
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });
    setSpeciesData(results.items[0]);
    //console.log(results.items[0]);
  };

  useEffect(() => {
    if (!state) {
      let tmp = pathname.slice(pathname.lastIndexOf("/"), pathname.length);
      //console.log(tmp)
      let cleanTmp = tmp.substring(1);
      //console.log(cleanTmp)
      fetchInfo(cleanTmp);
    } else {
      fetchInfo(state.data.slug);
    }
  }, []);

  return (
    <PageContainer
      style={{ alignItems: "center" }}
      title={speciesData?.scientific_name}
    >
      {/* <BoxWrapper>
                <Button sx={{ width: '10%' }} variant='contained' onClick={() => navigate(-1)}>Back</Button>
            </BoxWrapper> */}
      {speciesData ? (
        <DetailedContainer style={{ minWidth: "840px" }}>
          {speciesData.wwf_insights ? (
            <BoxWrapper style={{}}>
              <Typography textAlign="center" variant="h4">
                WWF Insights{" "}
              </Typography>
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "20px",
                  borderRadius: "10px",
                  padding: "5px",
                  textAlign: "center",
                }}
                dangerouslySetInnerHTML={convertFromHtml(
                  speciesData.wwf_insights
                )}
              />
            </BoxWrapper>
          ) : (
            <Box />
          )}
          <BoxWrapper style={{}}>
            <Typography textAlign="center" variant="h4">
              Species Information{" "}
            </Typography>
            <SpeciesInfoTable data={speciesData} />
          </BoxWrapper>
          <BoxWrapper style={{ height: "400px" }}>
            <Tooltip
              title={
                "Countries to which this species is native with the exception of certain plantation species that are non-native but where there is a risk of natural forest conversion."
              }
              placement={"top"}
            >
              <Typography textAlign="center" variant="h4">
                Countries of Origin
                <InfoOutlinedIcon fontSize={"small"}></InfoOutlinedIcon>{" "}
              </Typography>
            </Tooltip>
            <CountriesInSpecies props={speciesData.countries} />
          </BoxWrapper>
        </DetailedContainer>
      ) : (
        <CircularProgress color="secondary" />
      )}
    </PageContainer>
  );
}
