import React, { useEffect, useState, useMemo } from "react";
import PageContainer from "./PageContainer";
import DetailedContainer from "./DetailedContainer";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Typography, Box, Button, Link, Tooltip } from "@mui/material";
import { format } from "date-fns";
import { convertFromHtml } from "../constants";
import CircularProgress from "@mui/material/CircularProgress";
import IndicatorDataGrid from "../components/country/IndicatorDataGrid";
import SpeciesDataGrid from "../components/country/SpeciesDataGrid";
import { BoxWrapper } from "../components/BoxWrapper";
import RiskScoreTable from "../components/country/RiskScoreTable";
import RiskAssessmentTable from "../components/country/RiskAssessmentTable";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function DetailedCountry() {
  const theme = useTheme();
  const { state, pathname } = useLocation();
  // const { categories } = useCategories();

  //console.log(pathname);
  const navigate = useNavigate();
  let [countryData, setCountryData] = useState<any>();

  const fetchInfo = async (countrySlug: string) => {
    console.log(countrySlug);
    const results = await fetch(
      `/wagtail/api/v2/pages/?type=main.Country&fields=*,categories(*,section(*,indicators(*,indicator_section_category(*)))),indicators(*,fsc_report_field(*),fsc_risk_level_option(*)),species(*)&slug=${countrySlug}`
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });
    console.log(results);
    //console.log(results.species)
    setCountryData(results.items[0]);
  };

  const handleCompareClick = () => {
    navigate(`/compare?country=${countryData.meta.slug}`, {
      state: { id: countryData.id },
    });
  };

  useEffect(() => {
    if (!state) {
      let tmp = pathname.slice(pathname.lastIndexOf("/"), pathname.length);
      //console.log(tmp)
      let cleanTmp = tmp.substring(1);
      //console.log(cleanTmp)
      fetchInfo(cleanTmp);
    } else {
      fetchInfo(state.slug);
    }
  }, []);

  return (
    <PageContainer style={{ alignItems: "center" }} title={countryData?.name}>
      {/* <BoxWrapper>
                <Button sx={{ width: '10%' }} variant='contained' onClick={() => navigate('/assess-risk/countries')}>Back</Button>
            </BoxWrapper> */}
      {countryData ? (
        <DetailedContainer>
          <Button
            color="wwfGreen"
            variant="contained"
            sx={{ marginBottom: "10px" }}
            href={`/compare?country=${countryData.meta.slug}`}
          >
            Compare to Other Countries
          </Button>

          {countryData.wwf_insights ? (
            <BoxWrapper style={{}}>
              <Typography textAlign="center" variant="h4">
                WWF Insights{" "}
              </Typography>
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "20px",
                  borderRadius: "10px",
                  padding: "5px",
                  textAlign: "center",
                }}
                dangerouslySetInnerHTML={convertFromHtml(
                  countryData.wwf_insights
                )}
              />
            </BoxWrapper>
          ) : (
            <Box />
          )}

          <BoxWrapper style={{}}>
            <Typography textAlign="center" variant="h4">
              Risk Scores{" "}
            </Typography>
            <RiskScoreTable data={countryData} />
          </BoxWrapper>

          {countryData.risk_summary_reports.length > 0 &&
          countryData.fsc_document_link ? (
            <BoxWrapper style={{}}>
              <Tooltip
                title={
                  <React.Fragment>
                    Comprehensive assessment of social and environmental risks
                    associated with sourcing non-certified wood from specific
                    countries. Updated every five years. Sources: FSC
                    International Document Center and FSC Risk Assessment
                    Platform. For more information, see the{" "}
                    <Link
                      href="/understand-risk"
                      target="_blank"
                      style={{ color: "#add8e6" }}
                    >
                      Understanding Risk section
                    </Link>
                    .
                  </React.Fragment>
                }
                placement="top"
              >
                <Typography textAlign="center" variant="h4">
                  FSC National Risk Assessment
                  <InfoOutlinedIcon fontSize={"small"}></InfoOutlinedIcon>{" "}
                </Typography>
              </Tooltip>
              <Box
                sx={{ marginBottom: "15px" }}
                display="flex"
                flexDirection="row"
                justifyContent="space-around"
              >
                <Typography variant="subtitle1">
                  {" "}
                  Document Reference:{" "}
                  <b>
                    {" "}
                    {countryData.fsc_document_link ? (
                      <Link
                        target="_blank"
                        sx={{ cursor: "pointer" }}
                        href={countryData.fsc_document_link}
                      >
                        {countryData.fsc_document_link}
                      </Link>
                    ) : (
                      "No Document Available"
                    )}
                  </b>
                </Typography>
                <Typography variant="subtitle1">
                  {" "}
                  NRA Approval Date:{" "}
                  <b>
                    {countryData.nra_approval_date
                      ? format(new Date(countryData.nra_approval_date), "PPP")
                      : "No Data"}{" "}
                  </b>
                </Typography>
              </Box>
              {/* <Typography variant='h6' sx={{ textAlign: 'center' }}>Specified Risk Indicators</Typography> */}
              <RiskAssessmentTable data={countryData.risk_summary_reports} />

              {countryData.categories.map((category: any) => (
                <IndicatorDataGrid
                  category={category}
                  indicators={countryData.indicators}
                />
              ))}
            </BoxWrapper>
          ) : (
            <Box />
          )}

          <BoxWrapper style={{ height: "400px" }}>
            <Tooltip
              title={
                "A tree species native to one or more countries associated that is considered to be threatened by commercial trade and/or illegal logging, or trending toward threatened status."
              }
              placement={"top"}
            >
              <Typography textAlign="center" variant="h4">
                {" "}
                Species At Risk
                <InfoOutlinedIcon fontSize={"small"}></InfoOutlinedIcon>{" "}
              </Typography>
            </Tooltip>
            <SpeciesDataGrid props={countryData.species} />
          </BoxWrapper>
        </DetailedContainer>
      ) : (
        <CircularProgress color="secondary" />
      )}
    </PageContainer>
  );
}
