import { useState, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import { Box, Button, Typography, InputAdornment, TextField, Accordion, AccordionSummary, AccordionDetails, Chip, Modal, IconButton } from '@mui/material'
import { DataGrid, GridCellParams, GridColDef, GridRowParams } from '@mui/x-data-grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { convertFromHtml, modalStyle } from '../../constants'


interface Row {
    id: string;
    description: string;
    risk: string;
}

export default function IndicatorDataGrid({ category, indicators }: any) {
    //console.log(category);
    const theme = useTheme()
    const [rows, setRows] = useState<Row[]>([])
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [modalText, setModalText] = useState<any>('');

    const handleCellClick = (event: any) => {
        event.stopPropagation()
    }
    const handleClick = (event: any, cellValues: any) => {
        console.log(cellValues.row)
        event.stopPropagation()
        event.preventDefault();
        setModalText(cellValues.row.risk_notes)
        setOpenModal(true);
    }

    const handleClose = () => {

        setOpenModal(false);
        setModalText('')
    }

    useEffect(() => {
        setRows([])
        console.log(category)
        console.log(indicators)
        category.section.indicators.map((indicator: any) => {
            const i = indicators.find((x: any) => x.fsc_report_field.category_id === indicator.category_id &&
                x.fsc_report_field.indicator_id === indicator.indicator_id)
            let entryRow = {
                "id": `${indicator.indicator_section_category.major_section_number}.${indicator.indicator_id}`,
                "description": indicator.description,
                "risk": i.fsc_risk_level_option.risk_level,
                "risk_notes": i.risk_notes
            }
            setRows((rows) => [...rows, entryRow]);
        })

    }, [])

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 70, align: 'center', headerAlign: 'center' },
        {
            field: 'description',
            headerName: 'Description',
            editable: false,
            sortable: false,

            //width: 'auto',
            flex: 1

        },
        {
            field: 'risk_notes',
            headerName: 'risk_notes',
        },
        {
            field: 'risk',
            headerName: 'Risk Level',
            editable: false,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            //flex: 1,
            width: 98
        },
        {
            field: "Control Measures",
            width: 140,
            align: 'center',
            headerAlign: 'center',
            renderCell: (cellValues) => {
                if (cellValues.row.risk == 'Specified') {
                    return (

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={(event) => {
                                handleClick(event, cellValues);
                            }}
                            sx={{
                                fontFamily: 'Roboto',
                                fontSize: '12px',
                                padding: '5px'
                            }}
                        >
                            Control<br />Measures
                        </Button>
                    );
                } else {
                    return (

                        <Box />
                    );
                }

            }
        },

    ];


    return (
        <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"

                >
                    <Box display='flex' flexDirection='row' alignItems='center' justifyContent='start' flexWrap='wrap'>
                        <Typography sx={{ marginRight: '5px' }}>{category.section.section_label} </Typography>
                        {
                            category?.section.indicators.map((indicator: any) => {
                                const i = indicators.find((x: any) => x.fsc_report_field.category_id === indicator.category_id && x.fsc_report_field.indicator_id === indicator.indicator_id)
                                return <Chip size='small' sx={{ fontSize: '12px', marginLeft: '1px', marginRight: '1px' }} label={`${indicator.indicator_section_category.major_section_number}.${indicator.indicator_id}`} color={i.fsc_risk_level_option.risk_level == 'Low' || i.fsc_risk_level_option.risk_level == 'Non-Applicable' ? "info" : "warning"} />
                            })
                        }
                    </Box>

                </AccordionSummary>
                <AccordionDetails>
                    <Typography><Box dangerouslySetInnerHTML={convertFromHtml(category.section_notes)} /></Typography>
                    <DataGrid
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    risk_notes: false
                                }
                            }
                        }}
                        rowHeight={70}
                        sx={{
                            marginTop: '10px',
                            width: '100%',
                            height: '400px',
                            '& .Low': {
                                backgroundColor: '#d5f5d5',
                                '&:hover': {
                                    backgroundColor: '#d5f5d5'
                                }
                            },
                            '& .Specified': {
                                backgroundColor: '#ffa19c',
                                '&:hover': {
                                    backgroundColor: '#ffa19c'
                                }
                            },
                            '& .Non-Applicable': {
                                backgroundColor: '#d5f5d5',
                                '&:hover': {
                                    backgroundColor: '#d5f5d5'
                                }
                            },
                            '&.Mui-selected': {
                                backgroundColor: "rebeccapurple",
                            }
                        }}

                        rows={rows}
                        columns={columns}
                        getRowClassName={(params) => `${params.row.risk}`}
                        onCellClick={(event) => { handleCellClick(event) }}
                        //onRowClick={(event) => { handleCellClick(event) }}
                        disableSelectionOnClick
                        disableColumnFilter
                        disableColumnMenu
                        hideFooter

                    />
                </AccordionDetails>
            </Accordion>
            <Modal
                open={openModal}
                onClose={() => handleClose()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    overflow: 'scroll'
                }}
            >
                <Box sx={modalStyle}>
                    <IconButton onClick={() => setOpenModal(false)} sx={{ position: 'absolute', top: '0', right: '0' }} aria-label="delete">
                        <CloseIcon />
                    </IconButton>
                    {/* <Typography sx={{ fontWeight: 'bold' }}>{cellValues.row.id}</Typography> */}
                    <Box dangerouslySetInnerHTML={convertFromHtml(modalText)} />
                </Box>
            </Modal >
        </>
    )
}