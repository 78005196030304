import React from 'react'
import { Box } from '@mui/material'
import MainHeader from '../components/MainHeader';
import PageHeader from '../components/PageHeader';
import Footer from '../components/Footer';

type Props = {
    children: React.ReactNode,
    style?: React.CSSProperties,
    title: string,
    hideHeader?: boolean
}

function PageContainer({ children, title, style, hideHeader }: Props) {
    return (

        <Box
            id='page-container'
            display='flex'
            flexDirection='column'
            //alignItems='center'
            width='100%'
            margin={0}
            padding={0}
            //maxWidth='80vw'
            //minHeight='90vh'
            sx={{
                ...style,

                //background: '#D3D3D3',
                //height: 'auto',
                //fontFamily: 'Roboto',
            }}
        >
            {hideHeader? null : <PageHeader title={title} />}



            {children}


        </Box>
    )
}

export default PageContainer