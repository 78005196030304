import React from "react";
import { Box, Slider } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface Data {
  sliderType: string;
  data: number;
}
export default function RiskSlider({ sliderType, data }: Data) {
  console.log(data);
  const theme = useTheme();
  let dataValue = sliderType == "normal" ? data : data;
  let thumbColor =
    sliderType == "normal"
      ? dataValue > 66.6
        ? theme.palette.info.main
        : dataValue < 66.6 && dataValue > 33.3
        ? "#fdfd96"
        : theme.palette.warning.main
      : dataValue > 7
      ? theme.palette.info.main
      : dataValue < 7 && dataValue > 4
      ? "#fdfd96"
      : theme.palette.warning.main;

  let backgroundColor =
    "linear-gradient(to right, #ffa19c 0, #ffa19c 33.333%, #fdfd96 33.333%, #fdfd96 66.666%, #d5f5d5 66.666%, #d5f5d5 100%)";
  // sliderType == "normal"
  //   ? "linear-gradient(to right, red 0, red 33.333%, yellow 33.333%, yellow 66.666%, green 66.666%, green 100%)"
  //   : "linear-gradient(to right, red 0, red 3.3%, yellow 3.3%, yellow 6.6%, green 6.6%, green 10%)";

  let marks = [
    { value: 33.3, label: "33" },
    { value: 66.6, label: "66" },
  ];
  let ipi_marks = [
    { value: 4, label: "4" },
    { value: 7, label: "7" },
  ];
  return (
    // <div>
    //   <b>{data}</b>
    // </div>

    <Box display="flex" flexDirection="row" alignItems="center" gap="10px">
      <Box>{sliderType == "normal" ? 0 : 1}</Box>
      <Slider
        disabled
        sx={{
          cursor: "pointer",
          color: "blue",
          height: 12,
          width: "200px",
          marginBottom: "0px",
          "& .MuiSlider-rail": {
            backgroundImage: backgroundColor,
            opacity: 1,
          },
          "& .MuiSlider-thumb": {
            height: 20,
            width: 20,
            backgroundColor: thumbColor,
            // dataValue > 66.6
            //   ? "green"
            //   : dataValue < 66.6 && dataValue > 33.3
            //   ? "yellow"
            //   : "red",
            border: "1px solid black",

            // "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
            //   boxShadow: "inherit",
            // },
            // "&:before": {
            //   display: "none",
            // },
          },
          "& .MuiSlider-valueLabel": {
            fontFamily: "Roboto",
            fontSize: 14,
            fontWeight: "400",
            top: 0,
            color: "rgba(0, 0, 0, 0.87)",
            backgroundColor: "unset",

            "&:before": {
              display: "none",
            },
            "& *": {
              // background: "transparent",
            },
          },
          "& .MuiSlider-mark": {
            backgroundColor: "#000",
            height: "12px",
            width: "2px",

            // "&.MuiSlider-markActive": {
            //   opacity: 1,
            //   backgroundColor: "currentColor",
            // },
          },
          "& .MuiSlider-markLabel": {
            fontFamily: "Roboto",
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.87)",
          },
        }}
        value={dataValue}
        track={false}
        min={sliderType == "normal" ? 0 : 1}
        max={sliderType == "normal" ? 100 : 10}
        marks={sliderType == "normal" ? marks : ipi_marks}
        valueLabelDisplay="on"
      />
      <Box>{sliderType == "normal" ? 100 : 10}</Box>
    </Box>
  );
}
