import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Typography, InputAdornment, TextField } from '@mui/material'
import { DataGrid, GridCellParams, GridColDef, GridRowParams } from '@mui/x-data-grid';


interface Row {
    id: number;
    scientific_name: string;
    iucn_id: string;
    iucn_red: string;
    cites_id: string;
    common_names: string[];
    slug: string;
}

function SpeciesDataGrid({ props }: any) {



    const [rows, setRows] = useState<Row[]>([])
    const navigate = useNavigate();
    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'scientific_name',
            headerName: 'Scientific Name',
            editable: false,
            sortable: true,
            flex: 1

        },
        {
            field: 'common_names',
            headerName: 'Common Names',
            editable: false,
            sortable: true,
            flex: 2

        },
        // {
        //     field: 'iucn_id',
        //     headerName: 'IUCN ID',
        //     editable: false,
        //     sortable: false,
        //     width: 100
        // },
        // {
        //     field: 'iucn_red',
        //     headerName: 'IUCN Listing',
        //     editable: false,
        //     sortable: false,
        //     width: 120
        // },
        // {
        //     field: 'cites_id',
        //     headerName: 'Cites ID',
        //     editable: false,
        //     sortable: false,
        //     width: 100
        // },
    ];

    const trackedSpecies = (e: any) => {
        //console.log(e);
        //console.log(e.target.textContent);
        //console.log(e.target.id);
        navigate(`/assess-risk/species/${e.slug}`, { state: { data: { id: e.id, scientific_name: e.scientific_name, slug: e.slug } } })

    }


    useEffect(() => {
        setRows([])
        //console.log(props);

        props.map((specie: any) => {
            let entryRow = {
                "id": specie.id,
                "scientific_name": specie.scientific_name,
                'iucn_id': specie.iucn_id || 'none',
                'iucn_red': specie.iucn_red_listing || 'none',
                'cites_id': specie.cites_id || 'none',
                'common_names': specie.common_names.map((names: any) => names.common_name).join(', ') || 'No Common Names',
                'slug': specie.meta.slug

            }
            setRows((rows) => [...rows, entryRow]);
        })


    }, [])


    return (
        <DataGrid
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        id: false
                    }
                }
            }}
            sx={{
                marginTop: '10px',
                width: '100%',
                height: '400px',
                fontSize: '17px'
            }}
            rows={rows}
            columns={columns}
            disableColumnFilter
            disableColumnMenu
            onRowClick={(params: GridRowParams<any>) => {
                trackedSpecies(params.row)
            }}
            hideFooter
        //autoHeight

        />
    )
}

export default SpeciesDataGrid