import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import type { } from '@mui/x-data-grid/themeAugmentation';


declare module "@mui/material/styles" {
    interface Palette {
        sectionGrays: Palette["primary"];
        wwfGreen: Palette["primary"];
    }
    interface PaletteOptions {
        sectionGrays: PaletteOptions["primary"];
        wwfGreen: PaletteOptions["primary"];
    }
}
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        wwfGreen: true
    }
}

// Create a theme instance.
const theme = createTheme({
    typography: {
        fontFamily: ['Roboto', 'Oswald'].join(","),
        //fontFamily: ['Roboto', 'sans-serif'].join(','),
        body2: {
            fontFamily: ['Oswald', 'sans-serif'].join(','),
            fontSize: '15px'

        },
        body1: {
            //fontFamily: ['Oswald', 'sans-serif'].join(','),
            fontSize: '19px'
        },
        subtitle1: {
            fontSize: '15px'
        },
        h5: {
            fontSize: '15px',
            marginRight: '2px'
        },
        h3: {
            fontFamily: ['Oswald', 'sans-serif'].join(',')
        },
        h4: {
            //textDecoration: 'underline',
            padding: '10px',
            //marginBottom: '10px',
            background: '#add8e6'
        }
    },
    palette: {
        primary: {
            main: '#000000',
            contrastText: '#f5f5f5'
        },
        secondary: {
            main: '#add8e6',
            contrastText: '#d4ebf2'
        },
        error: {
            main: red.A400,
        },
        warning: {
            main: '#ffa19c',
            contrastText: '#000'
        },
        info: {
            main: '#d5f5d5',
            contrastText: '#000'
        },
        sectionGrays: {
            main: '#d3d3d3',
        },
        wwfGreen: {
            main: '#d5f5d5',

        }

    },
    components: {
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    fontFamily: 'Roboto',
                    marginBottom: '5px',
                    cursor: 'pointer',
                    '& .MuiDataGrid-cell:focus-within': {
                        outline: 'none'
                    }
                },
                row: {
                    "&.Mui-selected": {
                        backgroundColor: "#ffffed",

                    }
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontFamily: 'Roboto'
                }
            }
        },
        MuiModal: {
            styleOverrides: {
                root: {
                    '&.Mui-Backdrop': {
                        backgroundColor: 'white',
                    }
                }
            }
        },
    }
});

export default theme;

