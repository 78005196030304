import React from 'react'
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles'
import { Box, Button, Typography, Menu, MenuItem } from '@mui/material'



export default function AssessRiskMenu() {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <Box flexDirection='row' alignSelf='center' onMouseLeave={handleClose}>
            <Button
                // aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                //onClick={handleClick}
                onMouseOver={handleClick}
                //onMouseLeave={handleClose}
                style={{ textDecoration: 'none', color: theme.palette.primary.contrastText }}
            >
                <Typography variant='body2'>Assess Risk</Typography>
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ onMouseLeave: handleClose }}
            >
                <MenuItem component={Link} to='/assess-risk/countries' onClick={handleClose}><Typography variant='body2'>COUNTRIES</Typography></MenuItem>
                <MenuItem component={Link} to='/assess-risk/species' onClick={handleClose}><Typography variant='body2'>SPECIES</Typography></MenuItem>
            </Menu>

        </Box>
    )
}