import { useEffect, useState, useRef, useCallback } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import PageContainer from "./PageContainer";
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  TextField,
  LinearProgress,
  Stack,
  Tooltip,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridSortModel,
} from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import useSpecies from "../hooks/useSpecies";
import SearchBar from "../components/SearchBar";
import DetailedCountry from "../pages/DetailedCountry";

type Row = {
  id: number;
  scientific_name: string;
  common_names: string;
};

function AssessRiskSpecies() {
  const ref = useRef<HTMLDivElement>(null);
  const currentRef: any = ref.current;
  const navigate = useNavigate();
  const [pageRefresh, setPageRefresh] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("scientific_name");
  const { allSpecies, updateSpecies, searchSpecies, loadingSpecies } =
    useSpecies();
  const [rows, setRows] = useState<Row[]>([]);
  const [page, setPage] = useState(0);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "scientific_name",
      headerName: "Scientific Name",
      editable: false,
      sortable: true,
      flex: 1,
    },
    {
      field: "common_names",
      headerName: "Common Names",
      editable: false,
      sortable: false,
      flex: 1,
    },
  ];

  useEffect(() => {
    setRows([]);

    allSpecies.items?.map((item: any) => {
      let picked = {
        id: item.id,
        scientific_name: item.scientific_name,
        common_names: item.common_names
          .map((x: any) => x.common_name)
          .join(", "),
        slug: item.meta.slug,
      };
      setRows((rows) => [...rows, picked]);
    });
    //scrolls to the top on next/previous -- could be better
    if (currentRef) {
      console.log(currentRef);
      currentRef.querySelector(".MuiDataGrid-virtualScroller").scrollTop = 0;
    }
    setPageRefresh(false);
  }, [allSpecies, pageRefresh]);

  const keyPress = (e: any) => {
    // if (e.keyCode == 13) {
    setSearchInput(e.target.value);
    // }
  };

  useEffect(() => {
    const offset = page * 20;
    searchSpecies(searchInput, sortOrder, offset);
  }, [searchInput, sortOrder, page]);

  const handleRowSelect = (params: GridRowParams) => {
    //console.log(params.id);
    console.log(params.row.id);
    navigate(`/assess-risk/species/${params.row.slug}`, {
      state: { data: params.row, slug: params.row.slug },
    });
  };

  const inputSearch = () => {
    // setPageRefresh(true);
    // console.log(searchInput)
    // searchSpecies(searchInput, sortOrder)
  };

  // const checkIfBlank = (e: any) => {
  //
  //     setSearchInput(e.target.value)
  //     searchSpecies(e.target.value)
  //     //console.log('blank')
  //     if (e.target.value.length == 0) {
  //         getSpecies()
  //     }
  // }

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    // Here you save the data you need from the sort model
    // searchCountries({sortModel: [...sortModel]});
    console.log(sortModel);
    const sortOrder = sortModel
      .map((f) => `${f.sort === "desc" ? "-" : ""}${f.field}`)
      .join(",");
    console.log(sortOrder);
    setSortOrder(sortOrder);
  }, []);

  const CustomFooter = () => {
    const previousClick = () => {
      //console.log(currentData);
      updateSpecies(allSpecies.previous);
      setPageRefresh(true);
    };

    const nextClick = () => {
      console.log("next");
      updateSpecies(allSpecies.next);
      // setPageRefresh(true)
    };

    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Typography variant="body1" color="initial">
          Total: {allSpecies.count} |
        </Typography>
        <Button
          disabled={!allSpecies.previous}
          onClick={() => {
            previousClick();
          }}
        >
          Previous
        </Button>
        <Button
          disabled={!allSpecies.next}
          onClick={() => {
            nextClick();
          }}
        >
          Next
        </Button>
      </Box>
    );
  };

  return (
    <PageContainer title="Assess Species Risk">
      <Outlet />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        height="620px"
        width="100%"
      >
        <Box
          display="flex"
          width="80%"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <TextField
            // onChange={(e) => { checkIfBlank(e) }}
            onKeyUp={keyPress}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            label="Search for Species"
            sx={{
              flex: 2,
              // width: '75%',
              marginTop: "10px",
              marginBottom: "10px",
              marginRight: "10px",
            }}
          />
          <Button
            onClick={() => {
              inputSearch();
            }}
            sx={{ padding: "12px", marginRight: "5px", width: "15%" }}
            variant="contained"
          >
            Search
          </Button>
          <Tooltip
            title={"Download this table as an Excel sheet."}
            placement={"top"}
          >
            <Button
              href="/api/export/species/"
              target="_blank"
              variant="contained"
              sx={{ padding: "12px", marginRight: "5px", width: "15%" }}
            >
              Download Species
              <InfoOutlinedIcon
                fontSize={"small"}
                style={{ marginLeft: "3px" }}
              />
            </Button>
          </Tooltip>
        </Box>
        <DataGrid
          sx={{ width: "80%", height: "100%" }}
          components={{
            // Footer: CustomFooter,
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: () => (
              <Stack
                height="100%"
                marginLeft="20px"
                alignItems="center"
                justifyContent="center"
              >
                Are you sure your spelling is correct? If so, then WWF has not
                identified significant risks associated with the species you
                have searched for. If you believe we are in error, please
                contact us at info@wwfwoodrat.org.
              </Stack>
            ),
          }}
          componentsProps={{
            footer: allSpecies,
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          loading={loadingSpecies}
          rows={rows}
          columns={columns}
          rowsPerPageOptions={[]}
          pagination
          rowCount={
            allSpecies.meta !== undefined ? allSpecies.meta.total_count : 0
          }
          page={page}
          pageSize={20}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          disableColumnFilter
          disableColumnMenu
          onRowClick={(params: GridRowParams) => {
            handleRowSelect(params);
          }}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
        />
      </Box>
    </PageContainer>
  );
}

export default AssessRiskSpecies;
