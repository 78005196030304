import React, { useState, useEffect } from "react";
import { Typography, Box, Button, Link, Tooltip, Icon } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function RiskAssessmentTable({ data }: any) {
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    setTotal(
      data[0].section_1_score +
        data[0].section_2_score +
        data[0].section_3_score +
        data[0].section_4_score
    );
  }, [data]);

  return (
    <Box>
      <Tooltip
        title={
          <React.Fragment>
            The number of 32 discreet indicators evaluated by FSC National Risk
            Assessments that is determined to have elevated risk associated with
            a specific attribute. Control measures are identified for mitigating
            Specified Risk. Sources: FSC International Document Center and FSC
            Risk Assessment Platform. For more information, see the{" "}
            <Link
              href="/understand-risk"
              target="_blank"
              style={{ color: "#add8e6" }}
            >
              Understanding Risk section
            </Link>
            .{" "}
          </React.Fragment>
        }
        placement="top"
      >
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          Total Specified Risk Indicators
          <InfoOutlinedIcon fontSize={"small"}></InfoOutlinedIcon>: {total}
        </Typography>
      </Tooltip>
    </Box>
  );
}
